<template>
  <div class="reset-form">
    <div class="login-title">
      <i class="el-icon-unlock"></i>
      重置密码
    </div>
    <el-form ref="loginForm" :model="loginForm" label-width="0">
      <el-form-item prop="idCard" :rules="{required:true, message: '身份证号不能为空', trigger:'blur'}">
        <el-input type="text" v-model="loginForm.idCard" size="large"
                  maxlength="18"
                  placeholder="请输入身份证号">
          <template slot="prepend">身份证</template>
        </el-input>
      </el-form-item>
      <el-form-item prop="mobile" :rules="{required:true, message: '手机号不能为空', trigger:'blur'}">
        <el-input type="text" v-model="loginForm.mobile" size="large"
                  maxlength="11"
                  placeholder="请输入手机号">
          <template slot="prepend">手机号</template>
        </el-input>
      </el-form-item>
      <el-form-item prop="verifyCode" :rules="{required:true, message: '验证号不能为空', trigger:'blur'}">
        <div class="random-code-item">
          <div>
            <el-input type="text" v-model="loginForm.verifyCode" size="large"
                      :minlength="1" :maxlength="6"
                      placeholder="请输入验证码" @keypress.native="enterPress">
              <template slot="prepend">验证码</template>
            </el-input>
          </div>
          <el-tooltip content="点击更换验证码" effect="light">
            <el-image fit="fill"
                      :src="globalConfig.apiRoot+'/login/random-code-image?_t='+time"
                      @click="time = new Date().getTime()">
              <span slot="placeholder">loading...</span>
            </el-image>
          </el-tooltip>
        </div>
      </el-form-item>
    </el-form>
    <el-button type="primary" style="width: 100%" :loading="loading" @click="resetPassword">重置密码</el-button>
  </div>
</template>

<script>

import BaseVue from "@/components/base/BaseVue";
import CodeSelect from "@/components/base/CodeSelect";
import OrgSelect from "@/components/base/OrgSelect";
import AreaCascader from "@/components/base/AreaCascader";
import OrgInput from "@/components/base/OrgInput";
import {validatorEnum} from "@/enums";

export default {
  extends: BaseVue,
  components: {OrgInput, AreaCascader, OrgSelect, CodeSelect},

  data() {
    return {
      time: new Date().getTime(),
      loginForm: {
        idCard: '',
        mobile: '',
        verifyCode: ''
      }
    }
  },
  methods: {
    resetPassword() {
      const form = this.$refs["loginForm"];
      form.validate((success)=>{
        if(!success) {
          return;
        }
        this.loading = true
        this.postForm("/login/reset-password", this.loginForm).then((dr)=> {
          this.$alert(dr.msg).then(()=>{
            this.$router.push({
              path: "/login",
              query: this.$route.query
            })
          })
        }).catch((dr)=>{
          this.$message.error(dr.msg);
          this.loginForm.verifyCode = "";
          this.time = new Date().getTime();
        }).finally(()=>{
          this.loading = false
        });
      });
    },
    enterPress(e) {
      if(e.key == "Enter") {
        this.resetPassword();
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../css/variables";
.reset-form{
  width: 377px;
  height: 334px;
  margin: auto;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 6px;
  padding: 17px 36px;
  box-sizing: border-box;
  z-index: 99;
  position: relative;
  text-align: left;

  .login-title {
    font-size: 20px;
    color: #004080;
    letter-spacing: 0;
    margin-bottom: 24px;
  }

  .random-code-item {
    display: flex;
    justify-content: left;
    align-items: center;

    .el-image {
      width: 90px;
      height: 40px;
      margin-left: 5px;
      border-radius: 4px;
      border: 1px solid #DCDFE6;
      cursor: pointer;
    }
  }
}
</style>
