<template>
  <div class="login-page">
    <div class="login-page-header">
      <img src="../assets/logo.svg">
      <span>{{ globalConfig.title }}</span>
    </div>
    <div class="login-page-body">
      <reset-password-form></reset-password-form>
    </div>
  </div>
</template>

<script>

import BaseVue from "@/components/base/BaseVue";
import ResetPasswordForm from "@/components/ResetPasswordForm";

export default {
  extends: BaseVue,
  components: {ResetPasswordForm},

  data() {
    return {
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import "../css/variables";

.login-page {
  width: 100vw;
  min-width: 1024px;
  height: 100vh;
  background: #EFF2F6 url(../assets/loginBG.png) no-repeat;
  background-size: 100% 100%;

  &-header {
    width: 100vw;
    height: 80px;
    background: #fff;
    display: flex;
    align-items: center;

    img {
      height: 55px;
      margin-left: 15%;
    }

    span {
      color: #004080;
      font-size: 32px;
      font-weight: bold;
      padding: 0px 20px
    }
  }

  &-body {
    height: calc(100vh - 80px);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
